import * as React from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { Slot } from "@radix-ui/react-slot";

import { cn } from "~/lib/ui";

const buttonVariants = tv({
  base: 'inline-flex items-center justify-center rounded-md font-semibold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-stone-400 disabled:pointer-events-none disabled:text-neutralalpha-8 disabled:border-neutralalpha-6 aria-[disabled="true"]:pointer-events-none aria-[disabled="true"]:text-neutralalpha-8 aria-[disabled="true"]:border-neutralalpha-6 dark:focus-visible:ring-stone-800 [&_svg]:inline',
  variants: {
    variant: {
      default:
        'border text-white bg-brand-primary-black border-brand-primary-black hover:bg-brand-primary-black hover:border-neutralalpha-8 active:bg-neutralalpha-10 disabled:border-none disabled:bg-neutral-9 aria-[disabled="true"]:bg-neutral-9 aria-[disabled="true"]:text-neutral-3 aria-[disabled="true"]:border-0 disabled:text-neutral-3',
      outline:
        "border text-neutral-10 bg-brand-primary-black border-neutral-3 shadow-sm hover:shadow-none hover:border-neutralalpha-8 active:bg-neutralalpha-3 disabled:bg-neutralalpha-2",
      secondary:
        "border-2 border-dark-gray bg-white text-stone-900 shadow-sm hover:bg-stone-100/80 dark:bg-stone-800 dark:text-stone-50 dark:hover:bg-stone-800/80",
      ghost:
        "hover:bg-stone-100 hover:text-stone-900 dark:hover:bg-stone-800 dark:hover:text-stone-50",
      link: "text-stone-900 underline-offset-4 hover:underline dark:text-stone-50",
      linkInlineUnderlined:
        "inline text-stone-900 underline-offset-4 underline dark:text-stone-50",
      linkInline:
        "text-inherit relative m-0 flex h-fit gap-2 p-0 underline-offset-4 hover:underline dark:text-stone-50",
      loading:
        'border !flex text-brand-primary-black !bg-neutralalpha-10 border-neutralalpha-8 pointer-events-none disabled:text-brand-primary-black aria-[disabled="true"]:text-brand-primary-black',
      pointer: "text-black border bg-white border-brand-primary-black",
      roundedRight:
        "border rounded-none rounded-r-lg text-white bg-brand-primary-black border-brand-primary-black hover:bg-brand-primary-black hover:border-neutralalpha-8 active:bg-neutralalpha-10 disabled:border-none disabled:bg-neutral-9 disabled:text-neutral-3",
    },
    dangerous: {
      true: "text-red-500 hover:bg-red-600",
    },
    size: {
      "1": "h-5 rounded-1-max px-2 text-xs",
      "2": "h-6 rounded-2-max px-3 text-sm",
      "3": "h-7 rounded-3-max px-4 text-base",
      "4": "h-8 rounded-4-max px-5 text-lg",
      icon: "h-9 w-9",
      inherit: "text-inherit px-3",
      fit: "",
      full: "h-11 w-full",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "3",
  },
  compoundVariants: [
    {
      variant: "linkInline",
      className: "m-0 p-0 h-fit",
    },
    {
      variant: "linkInlineUnderlined",
      className: "m-0 p-0 h-fit",
    },
  ],
});

export interface ButtonProps<T extends React.ElementType = "button">
  extends VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  as?: T;
}

const ButtonComponent = <T extends React.ElementType = "button">(
  {
    className,
    variant,
    size,
    dangerous,
    asChild = false,
    as,
    ...props
  }: ButtonProps<T> &
    Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonProps<T>>,
  ref: React.ForwardedRef<React.ElementRef<T>>,
) => {
  const Comp = asChild ? Slot : as || "button";
  return (
    <Comp
      className={cn(buttonVariants({ variant, size, dangerous, className }))}
      ref={ref}
      {...props}
    />
  );
};

const Button = React.forwardRef(
  ButtonComponent as unknown as any,
) as unknown as <T extends React.ElementType = "button">(
  props: ButtonProps<T> & {
    ref?: React.ForwardedRef<React.ElementRef<T>>;
  } & Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonProps<T>>,
) => ReturnType<typeof ButtonComponent>;

export { Button, buttonVariants };
